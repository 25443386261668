.Wizard{
    flex-direction: column;
}

.HeaderWizard{
    display: flex;
    overflow-x: auto;
    width: 100%;
    /* background-color: red; */
    justify-content: center;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid #dee2e6;
}

.HeaderWizard-item{
    display: flex;
}

.HeaderWizard-item-number{
    width: 45px;
    height: 45px;
    background-color: #ddd;
    border-radius: 45px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

.HeaderWizard .HeaderWizard-item:last-child .HeaderWizard-item-line{
    display: none;
}

.HeaderWizard-item-line{
    width: 10rem;
    height: 7px;
    background-color: #e4e4e4;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: .5s all ease;
}

.HeaderWizard-item-info{
    position: relative;
    cursor: pointer;
    transition: .5s all ease;
}

.HeaderWizard-item-name{
    position: absolute;
    width: 200px;
    text-align: center;
    left: -76px;
    bottom: -35px;
    color: #b0b0b0;
    transition: .5s all ease;
}

.HeaderWizard-item_focus .HeaderWizard-item-number{
    background-color: #fff;
    border: 1px solid var(--bg-primary);
    color: var(--bg-primary);
    transition: .5s all ease;
}

.HeaderWizard-item_focus .HeaderWizard-item-name{
    color: var(--bg-primary);
}

.HeaderWizard-item_complete .HeaderWizard-item-number{
    background-color: var(--bg-primary);
    color: #fff;
}

.HeaderWizard-item_complete .HeaderWizard-item-name{
    color: var(--bg-primary);
}

.HeaderWizard-item_complete .HeaderWizard-item-line{
    background-color: var(--bg-primary);
}


.BodyWizard{
    background-color: #FAFAFA;
}
@keyframes fadeInLeftModal {
    from {
        opacity: 0;
        transform: translate3d(-1rem, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
.Wizard .animate__fadeInLeftModal {
    animation-name: fadeInLeftModal;
}

/* Dentro de una modal */

.modal-body .Wizard{
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    overflow: hidden;
}
.InputImage__input{
    padding: 15px !important;
    flex: 1 1;
    margin: 0 !important;
}

.InputImage__container{
    padding: 20px !important;
    flex: 1 1;
    background-color: #ffffff !important;
    border-radius: 0.25rem !important;
    border: 1px solid #ced4da !important;
}

.InputImages__container{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    gap: 1em;
}

@media (min-width: 576px){
    .InputImages__container{
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 768px){
    .InputImages__container{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.InputImages__container .box-input-file-elem:hover{
    cursor: grab;
}

.InputImages__container .box-input-file-elem{
    position: relative;
}

.InputImages__container .box-input-file-elem.isDragOver{
    /* content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: yellow;
    box-shadow: 0px 0px 10px yellow;
    left: -5px; */
    /* margin-left: 5rem !important; */
    background-color: transparent !important;
    box-shadow: none !important;
    
}

.InputImages{
    position: relative;
}

.InputImages__Containerloader{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffffb3;
}


.title-ccetic {
  font-weight: bolder;
  color: #fff;
  margin-bottom: 1.5rem;
}

.footer-login {
  /* margin-top: 2rem;   */
  /* position: absolute; */
  /* margin-bottom: 1.5rem; */
}

.footer-login ul {
  list-style: none;
  display: flex;
}

.footer-login ul a {
  color: #000;
  margin: 0 0.5rem;
  font-size: 0.8rem;
  opacity: 0.7;
  text-decoration: none;
  transition: 0.2s all ease;
}

.footer-login ul a:hover {
  opacity: 1;
  /* text-decoration: underline; */
}

#logo {
  /* max-width: 180px; */
  display: block;
  /* margin: 0 auto; */
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  /* filter: brightness(0) invert(1); */
  width: 100%;
  /* position: absolute; */
  /* top: -14rem; */
}

#btn-registrate{
  color: #FFF;
  opacity: 0.7;
  transition: 0.2s all ease;
  text-decoration: none;
}

#btn-registrate:hover{
  opacity: 1;
}

#passwordHelp{
  color: #4C4184;
  color: var(--bg-primary);
  color: #333;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: .875em;
}

#login-start .nav-tabs{
  border-bottom: 0 !important;
  position: relative;
  display: flex;
  justify-content: center;
}

#login-start .nav-tabs::after{
  /* content: ''; */
  position: absolute;
  width: calc(100% - 2px);
  top: calc(100% - -1px);
  left: 1px;
  height: 10px;
  background-color: #fff;
  z-index: 15;
  border-radius: 0.25rem;
}

#login-start .tab-pane .card{
  border-top-left-radius: 0;
}

#login-start .nav-link{
  position: relative;
  z-index: 5;
  color: #8a8f94;
  border: 0;
  border-bottom: 2px solid #ddd;
}

@media (max-width: 768px) {

  #login-start .nav-link{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

}


#login-start .nav-link.active{
  color: #495057;
  /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
  background-color: transparent !important;
  border: 0;
  border-bottom: 2px solid var(--bg-primary);
}

#login-start .nav-link.active::after{
  /* content: ''; */
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #fff;
  left: 0;
  top: 100%;
  z-index: 5;
}

#login-start .card{
  border: 0;
  box-shadow: none !important;
  background-color: transparent;
}

#login-start .card-footer{
  background-color: transparent;
  border: 0;
  text-align: center;
  padding: 0;
}

.container-login .col-12:nth-child(1) .card-body{
  position: relative;
}

@media (min-width: 992px) {

  .container-login .col-12:nth-child(1) .card-body::after{
    content: '';
    position: absolute;
    height: 90%;
    bottom: 0;
    width: 1px;
    background-color: #ddd;
    right: 0;
  }

}

#ModalRegistro{
  
}

#list-provincia-poblacion{
  max-height: 125px;
  overflow: auto;
  font-size: .9rem;
  padding-top: .5rem;
}

#login-start .nav-link{
  padding-left: 2rem;
  padding-right: 2rem;
}
.App-header{
  display: flex;
}

#login-start {
  width: 72%;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  order: 1;
  overflow: hidden;
  position: relative;
}

#login-end {
  /* order: 1; */
  /* background-image: url('../../assets/images/fondo.png'); */
  background-color: #46525E;
  /* background-color: #FFF;
  background-color: #EFF3F7; */
  /* background-color: #EDF3F8;
  background-color: #5FB559; */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 28%;
  /* width: 400px; */
  /* float: none !important; */
  padding: 0;
  position: relative;
  border-right: 7px;
  border-left: 0;
  border-style: solid;
  border-image: 
    linear-gradient(
      to bottom, 
      #2777B9, 
      #5FB559
    ) 1 100%;
}

@media (max-width: 992px) {
  .App-header {
    flex-direction: column;
  }

  #login-start {
    width: 100%;
    height: auto;
    min-height: auto;
  }

  #login-end {
    width: 100%;
    order: 1;
    height: auto;
    min-height: auto;
    border-right: 0;
  }
}

.owl-stage {
  display: flex;
}

.owl-item {
  margin: 0;
  padding: 0;
}

#logo-login{
  width: 110px;
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
}

/* #login-end::after{
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
} */

/* .login-end-layer{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
} */

@media (min-width: 992px) {

  .App-header #Footer{
    position: absolute;
    bottom: 0;
  }  

}

.card-test-info{
  margin: 1rem;
  /* background-color: rgba(255, 255, 255, 0.87); */
  z-index: 2;
  position: relative;
  border-radius: 5px;
  font-size: .9rem;
  padding: 1.3rem;
  color: #fff;
}

.card-test-info a{
  color: #fff !important;
}

.card-test-info p{
  opacity: .8;
}

.card-test-info-2{
  margin: 1rem;
  z-index: 2;
  position: relative;
  border-radius: 5px;
  color: #fff;
  font-size: .9rem;
}

@media (min-width: 1700px){
  #login-end h4{
    font-size: 1.8rem;
  }
  #login-end p{
    font-size: 1.1rem;
    margin-top: 2.5rem;
  }
}
#Footer{
    /* background-color: #333333; */
    color: #000;
    width: 100%;
    font-size: .8rem;
    order: 2;
    font-weight: 300;
}

#Footer > div{
    
    border-top: 1px solid #ddd;
}

#Footer a{
    color: #000;
    text-decoration: none;
    border-right: 1px solid #ccc;
    padding: 0 10px;
}

#Footer a:last-child {
    border-right: none;
}



.fixed-image {
  border-radius: 20px !important;
}
.img-cover {
  width: 100% !important;
  height: 240px !important; 
  object-fit: cover;
}
.ListDashboard .list-group-item:hover{
    background-color: #eee !important;
    cursor: pointer;
}
.Tree{
    position: relative;
}

.Tree>.Tree__item {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.Tree>.Tree__item:last-child {
    border-bottom: 1px solid #ddd;
}

.Tree__item {
    /* border: 1px solid #ddd; */
    border-bottom: 0;
}

.Tree__item:first-child {
    border-radius: 3px 3px 0 0;
}

.Tree__item:last-child {
    border-radius: 0 0 3px 3px;
    /* border-bottom: 1px solid #ddd; */
}

.Tree__item--active>.Tree__body {
    display: block;
}

.Tree__item--active>.Tree__head {
    border-bottom: 1px solid #ddd;
}

.Tree__item--active>.Tree__body>.Tree__item:first-child>.Tree__head {
    border-top: 0;
}

/* Head */

.Tree__head {
    border-top: 1px solid #ddd;
    position: relative;
}

.Tree__head.Tree__folder {
    cursor: pointer;
}


/* .Tree__item:last-child .Tree__head{
    border-bottom: 0;
} */

/* .Tree__item:first-child > .Tree__head{
    border-bottom: 1px solid #ddd;
} */

.Tree__head:hover {
    background-color: #f5f5f5;
}

/* Name */

.Tree__name {
    padding: .8rem 1rem;
    padding-right: 6rem;
}

/* Options */

.Tree__options {
    position: absolute;
    right: 0;
    font-size: 28px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
}

.Tree__options svg {
    transition: .3s ease all;
}

.Tree__options svg:hover {
    background-color: #e8e8e8;
    cursor: pointer;
    color: #212529;
}

/* Body */

.Tree__body {
    display: none;
    margin-left: 2rem;
    border-left: 1px solid #ddd;
}

.Layer-loading {
    position: relative;
}


.Layer-loading {
    content: '';
    position: absolute;
    background-color: rgb(234 234 234 / 50%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim 5s infinite;
}
/* 
@-webkit-keyframes anim {
    from {
        background: #ee6055 !important;
    }

    to {
        background: #ff9b85 !important;
    }
  }

@keyframes anim {
    from {
        background: #ee6055 !important;
    }

    to {
        background: #ff9b85 !important;
    }
} */

.ModalHelp svg{
    font-size: 1.6rem;
}
.Icon__Help{
    border-radius: 100%;
}

.Icon__Help svg{
    font-size: 1.4rem;
    transition: ease .3s all;
}

.Icon__Help svg:hover{
    color: #3ea7bc !important;
}
/* // Overwrite */

.list-group-item:hover{
    background-color: rgba(var(--bg-primary--hover-rgba), 0.1)!important;
    cursor: pointer;
}

.list-group-item.active{
    background-color: var(--bg-primary) !important;
    border-color: var(--bg-primary) !important;
}



.TableStripeRowSpan tr:hover{
    background-color: inherit !important;
    --bs-table-accent-bg: inherit !important
}

.TableStripeRowSpan tbody .tr_par{
    background-color: #f4f5f7 !important;
}

.TableStripeRowSpan .btn-link{
    color: #333;
    cursor: pointer;
}
.listgroup-plantillas .list-group-item{
    cursor: pointer;
}

.listgroup-plantillas .list-group-item:hover{
     background-color: #f4f4f4;
}

.list-group-item.active {
    background-color: var(--bg-primary) !important;
    border-color: var(--bg-primary) !important;
}
.accordion-item {
    border: 1px solid #dee2e6;
    border-radius: 5px;
}
.accordion-button {
    background-color: #f8f9fa;
    color: #495057;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    font-weight: bold;
}
.accordion-button:hover {
    background-color: #e9ecef;
}
.accordion-body {
    padding: 15px 20px;
}

.list-group-item {
    border: none;
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.list-group-item h5 {
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: #333333;
}
.list-group-item p {
    margin-bottom: 10px;
    color: #666666;
}
.list-group-item .btn-generar {
    padding: 8px 16px;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #2777b9;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.modal-contenido .modal-dialog {
  max-width: 80vw !important;
}

@media (max-width: 768px) {
  .modal-contenido .modal-dialog {
    max-width: 100vw !important;
  }
}
#NavTop-legal{
  background-color: #FFF;
  /* height: 16rem; */
  border-bottom: 1px solid #e8e8e8;
  color: #333;
}

#NavTop-legal ul{
  list-style: none;
  padding: 0;
  margin: 0;
}

#NavTop-legal ul a{
  color: #333;
  text-decoration: none;
  font-size: .9rem;
  opacity: .7;
  margin-left: 1rem;
}

#NavTop-legal ul a.active,
#NavTop-legal ul a:hover{
  opacity: 1;
}

#NavTop-legal #logo{
  max-width: 270px;
}

#Main-legal{
  position: relative;
  padding-top: 2rem;
  background-color: #f2f4f6;
}


.Main-legal-content{
  background-color: #fff;
  padding: 2rem;
  /* padding-top: .5rem; */
  border-radius: .4rem .4rem 0 0;
}

.bg-opacity-primary{
  background: #ecf0f5;
}

.Main-legal-content h4{
  /* position: absolute; */
  top: -3.5rem; 
  /* color: #fff; */
  font-size: 1.7rem;
  font-weight: 700;
  left: 0;
  right: 0;
  margin-bottom: 2rem;
}

.Main-legal-content a{
  color: #4c4184;
  color: var(--bg-primary);
  /* color: #000; */
}


@media (max-width: 992px) {
  #Main-legal{
    /* top: -2rem; */
  }

  #NavTop-legal{
    /* height: 18rem; */
  }

  #NavTop-legal ul a{
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .Main-legal-content{
    padding: 1rem;
  }
}


.toggle-menu .NavLinksInner{
    margin-right: 0;
}

.NavLinksInner{
    margin-right: 3px;
    overflow: hidden;
}

.toggle-menu .NavLinksInner:hover {
    overflow-y: hidden;
}

.NavLinksInner:hover {
    overflow-y: scroll;
}

.NavLinksInner::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #212529;
}

.NavLinksInner::-webkit-scrollbar
{
	width: 6px;
	background-color: #212529;
}

.NavLinksInner::-webkit-scrollbar-thumb
{
    border-radius: 4px;
	background-color: rgba(255,255,255,0.5);
}

#logo-inner{
    max-width: 180px;
    display: block;
    /* padding-top: .3rem;
    padding-bottom: .3rem; */
    /* filter: brightness(0) invert(1); */
    /* width: 40px; */
    height: 35px;
}

.NavStartInner {
    width: 200px;
    background-color: #212529;
    height: 100vh;
    padding-top: 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    transition: transform 0.3s ease;
}

.NavStartInner .nav-link {
    font-weight: 400;
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0 0.25rem 0.25rem 0;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: left;
    text-align: left;
    /*border-bottom: 1px solid #1c1e1f;*/
}

.NavStartInner .nav-link:hover {
    color: #fff;
}

.NavStartInner .nav-link.active {
    color: #3774b2;
    /*background-color: #fff;*/
}

.NavStartInner .nav-item {
    position: relative;
}

.NavStartInner .nav-item .nav-link {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.NavStartInner .nav-item .nav-dropdown {
    display: none;
    padding-left: 1.5rem;
    background-color: #1c1e1f;
}

.NavStartInner .nav-item.open .nav-dropdown {
    display: block;
}

.NavStartInner .nav-dropdown .nav-link {
    font-size: 0.9rem;
    /*color: rgba(255, 255, 255, 0.8);*/
}

.NavStartInner .nav-dropdown .nav-link.active {
    color: #3774b2;
    font-size: 0.9rem;
    background-color: transparent;
}

.NavStartInner footer {
    color: rgba(255, 255, 255, 0.6);
}

.NavStartInner footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.NavStartInner footer ul a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}

.NavStartInner small {
    font-size: 0.7rem;
}

.NavStartInner h4 {
    font-weight: bolder;
    text-align: center;
}

@media (max-width: 768px) {
    .NavStartInner {
        position: absolute;
        padding-top: 2.5rem;
        left: -100%;
        z-index: 11;
        box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;
    }
}

.NavStartInner.active {
    transform: translateX(0);
}

.NavStartInner .active .nav-link {
    color: #ffffff;
}

.NavStartInner .nav-link {
    width: 100%;
}

.sidebar-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #fff;
    z-index: 1000;
    cursor: pointer;
}

.NavStartInnerMobile {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #212529;
    transition: left 0.3s ease;
    z-index: 1030;
    overflow-y: auto;
}

.NavStartInnerMobile.open {
    left: 0;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1025;
}

.NavStartInnerMobile .nav-dropdown {
    display: none;
    padding-left: 1.5rem;
    background-color: #1c1e1f;
}

.NavStartInnerMobile .nav-item.open .nav-dropdown {
    display: block;
}

.NavStartInnerMobile .NavtopInner{
   /*z-index: 1050 !important;*/
}
.NavtopInner{
    background-color: #ECF0F5;
    position: fixed;
    z-index: 5;
    z-index: 20;
    width: 100%;
    background: #4c4184;
    color: #333;
    padding-top: .4rem;
    padding-bottom: .4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;
}

.NavtopInner button{
    background-color: transparent !important;
    box-shadow: none !important;
    outline: 0 !important;
    border: 0 !important;
}

.NavtopInner button svg{
    font-size: 1.4rem;
}


@media (min-width: 768px){
    #container-logo-inner{
        /* width: 193px; */
    }
    
}
/* Config */

:root {
  --bg-primary: #3774b2;
  --bg-primary-rgb: 39, 119, 185;
  --bg-primary-dark: #b30101;
  --color-dark: #22242a;
  --color-light-grey: #aeb3b7;
  --color-grey: #f4f4f4;
  --light: rgb(210 219 233);
  --bg-neutral: #ffffff;
  --text-secondary: #6f7178;
  --light-20: rgba(225, 231, 240, 0.2);
  --success: #28a745;
}

/* Imports Fonts */

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Air.21e0b594.otf) format('opentype');
  font-weight: air;
}

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Thin.984c5adb.otf) format('opentype');
  font-weight: 100;
}

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Light.8b596560.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Regular.844324dc.otf) format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Medium.79b61679.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Bold.3ab5a630.otf) format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Aeonik;
  src: url(https://www.dapcons.com/static/media/Aeonik-Black.8c3416ed.otf) format('opentype');
  font-weight: 900;
}

body{
  font-family: Aeonik, sans-serif;
  background-color: #f2f4f6;
}

.color-primary{
  color: #3774b2;
  color: var(--bg-primary);
}

/* Overwrite bootstrap */

.btn-primary {
  background-color: #3774b2 !important;
  background-color: var(--bg-primary) !important;
  border-color: #3774b2 !important;
  border-color: var(--bg-primary) !important;
  /* background-image: linear-gradient(to right, #5FB559 , #2777B9) !important;  */
}

.btn-primary:focus{
  background-color: #3774b2;
  background-color: var(--bg-primary);
  border-color: #3774b2;
  border-color: var(--bg-primary);
}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: #3774b2;
  background-color: var(--bg-primary);
  border-color: #3774b2;
  border-color: var(--bg-primary);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled{
  color: #3774b2;
  color: var(--bg-primary);
}

.btn-outline-secondary:hover{
  background-color: #6c757d !important;
}


.btn:focus{
  box-shadow: none !important;
  outline: 0;
}

.btn-primary:hover {
  background-color: #5f539b;
  border-color: #5f539b;
}

.btn-info{
  background-color: #3a78b9 !important;
  border-color: #3a78b9 !important;
  color: #FFF !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #ca515d !important;
  border-color: #ca515d !important;
}

.btn-transparent{
  color: #6f7178;
  color: var(--text-secondary);
  background-color: transparent;
  border: 0;
  line-height: 0;
}

.btn-default {
  background-color: rgb(239, 239, 239);
  color: rgb(59, 59, 59);
}

.btn {
  border-radius: 2px;
}

.shadow-lg {
  /* box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important; */
  /* box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2) !important; */
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
}

.page-link {
  color: #000;
}

.page-item.active .page-link {
  background-color: #3774b2;
  background-color: var(--bg-primary);
  border-color: #3774b2;
  border-color: var(--bg-primary);
}

.btn-outline-primary {
  color: #3774b2;
  color: var(--bg-primary);
  border-color: #3774b2;
  border-color: var(--bg-primary);
  background-color: #fff;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #3774b2 !important;
  background-color: var(--bg-primary) !important;
  border-color: #3774b2;
  border-color: var(--bg-primary);
}

.btn-outline-danger:hover{
  background-color: #dc3545 !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #22242a;
  border-color: var(--color-dark);
  outline: 0;
  box-shadow: none;
}

.form-select:focus {
  color: #212529;
  background-color: #fff;
  border-color: #22242a;
  border-color: var(--color-dark);
  outline: 0;
  box-shadow: none;
}

.table-hover>tbody>tr:hover{
  --bs-table-accent-bg: rgb(0 0 0 / 4%);
}

/* .table thead tr{
  background-color: #f2f2f2;
} */

.fw-500 {
  font-weight: 500;
}

.fs-105{
  font-size: 1.05rem;
}

.me-25{
  margin-right: .75rem !important;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 4rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* Pages */

.Side-end {
  width: calc(100% - 200px);
  float: right;
  padding-top: 3.5rem;
  background-color: #f2f4f6;
  /* top: 3rem; */
  /* position: relative; */
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.Column-small {
  border-radius: 0.25rem;
  background-color: #fff;
  padding: 1.2rem;
  height: auto;
  align-self: self-start;
  top: 5rem;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  /*border: 1px solid rgba(0, 0, 0, 0.125);*/
}
/*.breadcrumb{
  background-color: #fff;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}*/

.MainInner{
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #FFF;
  padding: 1.2rem !important;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
  /*border: 1px solid rgba(0, 0, 0, 0.125);*/
}

.bg-opacity-primary{
  background: #ecf0f5;
}

@media (max-width: 1200px) {

  .Column-small{
    width: 100% !important;
    position: static !important;
    z-index: 1;
    margin-bottom: 1.5rem;
  }

}

@media (max-width: 768px) {

  .Side-end{
    width: 100% !important;
    padding-left: .8rem;
    padding-right: .8rem;
  }

  .Column-small{
    padding: 1rem;
  }

  .MainInner{
    padding: 1rem !important;
  }

}

.container-inner{
  background-color: #f2f4f6;
}

.Breadcrumb-inner{
  font-size: 1.1rem;
  font-weight: 700;
}

.Breadcrumb-inner a{
  color: #000;
  text-decoration: none;
}

#icon-bars{
  cursor: pointer;
}

.cursor-pointer{
  cursor: pointer;
}

/* Toggle main menu */

/*.toggle-menu{
  left: 0;
  width: 50px;
}

.toggle-menu footer,
.toggle-menu span{
  display: none !important;
}

.toggle-menu .nav-link div{
  margin: 0 !important;
  width: auto !important;
}

.toggle-menu ~ .Side-end{
  width: calc(100% - 50px);
}*/

#fade-menu{
  width: 0%;
  position: fixed;
  min-height: 100vh;
  background-color: transparent;
  z-index: 7;
  transition: .3s background ease;
}

.toggle-fade-menu{
  width: 100% !important;
  background-color: #0006 !important;
}

.table-fixed-end > tbody > tr > td:last-child,
.table-fixed-end > thead > tr > th:last-child
{
  position: sticky;
  background: #f5f5f5;
  right: 0;
  white-space: nowrap;
  box-shadow: inset 1px 0 #ddd;
}

.table-fixed-end > thead > tr > th:last-child{
  background: #f5f5f5;
  box-shadow: inset 1px 0 #ddd;

}

.table-orderby-active{
  color: #3a78b9;
  font-weight: 900;
}

.form-select:disabled {
  /* background-color: #efefef; */
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

/* Leyenda */

.leyenda td{
  background-color: #f9fafc;
  box-shadow: none;
}

.item-leyenda svg{
  font-size: .6em !important;
}

.item-leyenda{
  margin-right: 2em; 
  display: flex; 
  align-items: center;
  color: #6f7178 !important;
  color: var(--text-secondary) !important;
}

.tableMain {
  border: 1px solid rgb(210 219 233);
  border: 1px solid var(--light);
}

.tableMain > thead > tr > th, .tableMain > tbody > tr > td {
  border-top: none;
  font-size: 14px;
  line-height: 1.43rem;
  padding: 10px 14px;
}

.table>:not(:last-child)>:last-child>*{
  border-bottom-color: #dee2e6;
}

.table-bordered>:not(caption)>*>*{
  border-width: 0;
}

.table tbody tr.tr-firmado {
  background-color: #28a7450f;
}

.tableMain > tbody > tr:hover {
  background-color: rgba(225, 231, 240, 0.2);
  background-color: var(--light-20);
  --bs-table-accent-bg: var(--light-20);
}

/* Modal ver mas */

.btn-modalVermas{
  cursor: pointer;
  color: #6f7178;
  color: var(--text-secondary);
  transition: all ease .3s;
  font-size: 1.4rem;
}

.btn-modalVermas:hover{
  color: #333;
}

.shrink-td {
  width: 0.1%;
  white-space: nowrap;
}

@media (min-width: 992px){

  .modal-lg, .modal-xl {
    max-width: 900px;
  }
}

#result-document {
  background-color: #fff;
  min-height: 300px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

#result-document-image {
  border: 1px solid #C4C4C4;
  box-shadow: 1px 3px 6px #ddd;
  position: relative;
  z-index: 20;
}

#download-file {
  position: relative;
  z-index: 20;
}


/* Modal ver mas */

.btn-modalVermas{
  cursor: pointer;
  color: #6f7178;
  color: var(--text-secondary);
  transition: all ease .3s;
}

.btn-modalVermas:hover{
  color: #333;
}

#tab-vermas .nav,
#tab-vermas .nav li{
  position: relative;
  z-index: 10;
}

#tab-vermas .nav-tabs{
  border-bottom: 0;
}

#tab-vermas .nav-link {
  position: relative;
  z-index: 5;
  color: #8a8f94;
}

@media (min-width: 992px) { 

  #tab-vermas .nav{
      padding-top: 3.5rem;
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem !important;
  }
}

@media (max-width: 991.98px) { 
  #tab-vermas .nav{
      font-size: .9rem;
      padding-top: 2rem;
  }
}

#tab-vermas .nav::after{
  content: '';
  background-color: #F9F9F9;
  position: absolute;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  right: -2rem;
  bottom: 0;
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: #3774b2 !important;
  color: var(--bg-primary) !important;
}

#modalVermas table{
  background-color: #fff;
}

#download-file{
  position: relative;
  z-index: 20;
}

#modalVermas table {
  background-color: #fff;
}

.disabledCursor { 
  pointer-events: none;
}

.disabledCursorInput { 
  pointer-events: none;
}

.disabledCursorInput input {
  background-color: transparent !important;
}

.dropdown-item.active, .dropdown-item:active{
  background-color: #3774b2;
  background-color: var(--bg-primary);
}

.list-group-item.active{
  background-color: #3774b2;
  background-color: var(--bg-primary);
  border-color: #3774b2;
  border-color: var(--bg-primary);
}

/* .btn-outline-primary{
  color: var(--bg-primary);
  border-color: var(--bg-primary);
}

.btn-outline-primary:active{
  background-color: var(--bg-primary);;
  border-color: var(--bg-primary);;
} */

#result-document-image > div{
  background-color: #fff;
}

.container-inner{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 768px){
  .container-inner{
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }
}

.breadcrumb-item{
  font-weight: 500;
}

.btn-for-icon{
  font-size: 1.3rem;
}

.form-check-input:checked{
  background-color: #3774b2;
  background-color: var(--bg-primary);
    border-color: #3774b2;
    border-color: var(--bg-primary);
  }
  
.form-check-input{
  box-shadow: none !important;
  /* border-color: inherit; */
  border: 1px solid rgba(0,0,0,.25) !important;
}


.pointer-events-none{
  pointer-events: none;
}

/* Modal */

.modal-header .btn-close {
  position: absolute;
  right: 1rem;
}

/* Arreglando Modals z-index */

.fade.modal-backdrop.show{
  z-index: 1060;  
}

.align-input-label{
  height: 38px;
  margin-top: 2rem;
}

.alert-warning{
  border-color: #f3d684;
}

.table>thead{
  background: #f1f5f9;
}

.container-input-danger input{
  background-color: #ffe9e9 !important;
}

.tr-red {
  background-color: #ffebeb!important;
}

.text-justify{
  text-align: justify;
}

.inputcheck--lg input{
  width: 20px;
  height: 20px;
}

.card-img-top {
  width: 100% !important;
  height: auto !important;
}

.bg-orange{
  background-color: #f15b26;
}

.bg-primary-dapcons{
  background-color: #3774b2;
}

.bg-success-dapcons{
  background-color: #248f47;
}

.text-orange{
  color: #f15b26;
}

.text-primary-dapcons{
  color: #3774b2;
}

.text-success-dapcons{
  color: #209047;
}

/* Estilo generales table */
.tableMain thead th {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.tableMain thead th {
  font-weight: bold !important;
}

.tableMain tbody tr td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.card-border-top {
    border-top: 2px solid #0d6efd !important;
}
.total-badge {
    font-size: 2rem;
}

.legend {
  display: flex;
  flex-direction: column;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #000;
}

.tab-active {
  background-color: #007bff;
  color: white !important;
  border-radius: 4px;
}


.table tbody td {
  vertical-align: middle;
  padding: 1rem;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

.text-center {
  text-align: center;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

label.radio-card {
  cursor: pointer;
  margin: .5em;
}
label.radio-card .card-content-wrapper {
  background: #fff;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  transition: 200ms linear;
  position: relative;
  min-width: 170px;
}
label.radio-card .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  transition: 200ms linear;
  position: absolute;
  right: -10px;
  top: -10px;
}
label.radio-card .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}
label.radio-card input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
label.radio-card input[type=radio]:checked + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3774b2;
}
label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon {
  background: #3774b2;
  border-color: #3774b2;
  transform: scale(1.2);
}
label.radio-card input[type=radio]:checked + .card-content-wrapper .check-icon:before {
  transform: scale(1);
  opacity: 1;
}
label.radio-card input[type=radio]:focus + .card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
  border-color: #3774b2;
}

label.radio-card .card-content h4 {
  font-size: 16px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #1f2949;
  margin: 0;
}
label.radio-card .card-content h5 {
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: #686d73;
}

