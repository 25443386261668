/* // Overwrite */

.list-group-item:hover{
    background-color: rgba(var(--bg-primary--hover-rgba), 0.1)!important;
    cursor: pointer;
}

.list-group-item.active{
    background-color: var(--bg-primary) !important;
    border-color: var(--bg-primary) !important;
}

