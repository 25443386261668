.App-header{
  display: flex;
}

#login-start {
  width: 72%;
  background-color: #f8f8f8;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  order: 1;
  overflow: hidden;
  position: relative;
}

#login-end {
  /* order: 1; */
  /* background-image: url('../../assets/images/fondo.png'); */
  background-color: #46525E;
  /* background-color: #FFF;
  background-color: #EFF3F7; */
  /* background-color: #EDF3F8;
  background-color: #5FB559; */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 28%;
  /* width: 400px; */
  /* float: none !important; */
  padding: 0;
  position: relative;
  border-right: 7px;
  border-left: 0;
  border-style: solid;
  border-image: 
    linear-gradient(
      to bottom, 
      #2777B9, 
      #5FB559
    ) 1 100%;
}

@media (max-width: 992px) {
  .App-header {
    flex-direction: column;
  }

  #login-start {
    width: 100%;
    height: auto;
    min-height: auto;
  }

  #login-end {
    width: 100%;
    order: 1;
    height: auto;
    min-height: auto;
    border-right: 0;
  }
}

.owl-stage {
  display: flex;
}

.owl-item {
  margin: 0;
  padding: 0;
}

#logo-login{
  width: 110px;
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
}

/* #login-end::after{
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
} */

/* .login-end-layer{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
} */

@media (min-width: 992px) {

  .App-header #Footer{
    position: absolute;
    bottom: 0;
  }  

}

.card-test-info{
  margin: 1rem;
  /* background-color: rgba(255, 255, 255, 0.87); */
  z-index: 2;
  position: relative;
  border-radius: 5px;
  font-size: .9rem;
  padding: 1.3rem;
  color: #fff;
}

.card-test-info a{
  color: #fff !important;
}

.card-test-info p{
  opacity: .8;
}

.card-test-info-2{
  margin: 1rem;
  z-index: 2;
  position: relative;
  border-radius: 5px;
  color: #fff;
  font-size: .9rem;
}

@media (min-width: 1700px){
  #login-end h4{
    font-size: 1.8rem;
  }
  #login-end p{
    font-size: 1.1rem;
    margin-top: 2.5rem;
  }
}