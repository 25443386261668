
.toggle-menu .NavLinksInner{
    margin-right: 0;
}

.NavLinksInner{
    margin-right: 3px;
    overflow: hidden;
}

.toggle-menu .NavLinksInner:hover {
    overflow-y: hidden;
}

.NavLinksInner:hover {
    overflow-y: scroll;
}

.NavLinksInner::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #212529;
}

.NavLinksInner::-webkit-scrollbar
{
	width: 6px;
	background-color: #212529;
}

.NavLinksInner::-webkit-scrollbar-thumb
{
    border-radius: 4px;
	background-color: rgba(255,255,255,0.5);
}
