
.TableStripeRowSpan tr:hover{
    background-color: inherit !important;
    --bs-table-accent-bg: inherit !important
}

.TableStripeRowSpan tbody .tr_par{
    background-color: #f4f5f7 !important;
}

.TableStripeRowSpan .btn-link{
    color: #333;
    cursor: pointer;
}