.Wizard{
    flex-direction: column;
}

.HeaderWizard{
    display: flex;
    overflow-x: auto;
    width: 100%;
    /* background-color: red; */
    justify-content: center;
    padding-bottom: 3.5rem;
    border-bottom: 1px solid #dee2e6;
}

.HeaderWizard-item{
    display: flex;
}

.HeaderWizard-item-number{
    width: 45px;
    height: 45px;
    background-color: #ddd;
    border-radius: 45px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

.HeaderWizard .HeaderWizard-item:last-child .HeaderWizard-item-line{
    display: none;
}

.HeaderWizard-item-line{
    width: 10rem;
    height: 7px;
    background-color: #e4e4e4;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    transition: .5s all ease;
}

.HeaderWizard-item-info{
    position: relative;
    cursor: pointer;
    transition: .5s all ease;
}

.HeaderWizard-item-name{
    position: absolute;
    width: 200px;
    text-align: center;
    left: -76px;
    bottom: -35px;
    color: #b0b0b0;
    transition: .5s all ease;
}

.HeaderWizard-item_focus .HeaderWizard-item-number{
    background-color: #fff;
    border: 1px solid var(--bg-primary);
    color: var(--bg-primary);
    transition: .5s all ease;
}

.HeaderWizard-item_focus .HeaderWizard-item-name{
    color: var(--bg-primary);
}

.HeaderWizard-item_complete .HeaderWizard-item-number{
    background-color: var(--bg-primary);
    color: #fff;
}

.HeaderWizard-item_complete .HeaderWizard-item-name{
    color: var(--bg-primary);
}

.HeaderWizard-item_complete .HeaderWizard-item-line{
    background-color: var(--bg-primary);
}


.BodyWizard{
    background-color: #FAFAFA;
}

@-webkit-keyframes fadeInLeftModal {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-1rem, 0, 0);
      transform: translate3d(-1rem, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
@keyframes fadeInLeftModal {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-1rem, 0, 0);
        transform: translate3d(-1rem, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
.Wizard .animate__fadeInLeftModal {
    -webkit-animation-name: fadeInLeftModal;
    animation-name: fadeInLeftModal;
}

/* Dentro de una modal */

.modal-body .Wizard{
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    overflow: hidden;
}