#logo-inner{
    max-width: 180px;
    display: block;
    /* padding-top: .3rem;
    padding-bottom: .3rem; */
    /* filter: brightness(0) invert(1); */
    /* width: 40px; */
    height: 35px;
}

.NavStartInner {
    width: 200px;
    background-color: #212529;
    height: 100vh;
    padding-top: 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    transition: transform 0.3s ease;
}

.NavStartInner .nav-link {
    font-weight: 400;
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0 0.25rem 0.25rem 0;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: left;
    text-align: left;
    /*border-bottom: 1px solid #1c1e1f;*/
}

.NavStartInner .nav-link:hover {
    color: #fff;
}

.NavStartInner .nav-link.active {
    color: #3774b2;
    /*background-color: #fff;*/
}

.NavStartInner .nav-item {
    position: relative;
}

.NavStartInner .nav-item .nav-link {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.NavStartInner .nav-item .nav-dropdown {
    display: none;
    padding-left: 1.5rem;
    background-color: #1c1e1f;
}

.NavStartInner .nav-item.open .nav-dropdown {
    display: block;
}

.NavStartInner .nav-dropdown .nav-link {
    font-size: 0.9rem;
    /*color: rgba(255, 255, 255, 0.8);*/
}

.NavStartInner .nav-dropdown .nav-link.active {
    color: #3774b2;
    font-size: 0.9rem;
    background-color: transparent;
}

.NavStartInner footer {
    color: rgba(255, 255, 255, 0.6);
}

.NavStartInner footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.NavStartInner footer ul a {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
}

.NavStartInner small {
    font-size: 0.7rem;
}

.NavStartInner h4 {
    font-weight: bolder;
    text-align: center;
}

@media (max-width: 768px) {
    .NavStartInner {
        position: absolute;
        padding-top: 2.5rem;
        left: -100%;
        z-index: 11;
        box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease;
    }
}

.NavStartInner.active {
    transform: translateX(0);
}

.NavStartInner .active .nav-link {
    color: #ffffff;
}

.NavStartInner .nav-link {
    width: 100%;
}

.sidebar-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #fff;
    z-index: 1000;
    cursor: pointer;
}

.NavStartInnerMobile {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #212529;
    transition: left 0.3s ease;
    z-index: 1030;
    overflow-y: auto;
}

.NavStartInnerMobile.open {
    left: 0;
}

.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1025;
}

.NavStartInnerMobile .nav-dropdown {
    display: none;
    padding-left: 1.5rem;
    background-color: #1c1e1f;
}

.NavStartInnerMobile .nav-item.open .nav-dropdown {
    display: block;
}

.NavStartInnerMobile .NavtopInner{
   /*z-index: 1050 !important;*/
}