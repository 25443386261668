.accordion-item {
    border: 1px solid #dee2e6;
    border-radius: 5px;
}
.accordion-button {
    background-color: #f8f9fa;
    color: #495057;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    font-weight: bold;
}
.accordion-button:hover {
    background-color: #e9ecef;
}
.accordion-body {
    padding: 15px 20px;
}

.list-group-item {
    border: none;
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.list-group-item h5 {
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: #333333;
}
.list-group-item p {
    margin-bottom: 10px;
    color: #666666;
}
.list-group-item .btn-generar {
    padding: 8px 16px;
}

.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #2777b9;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}