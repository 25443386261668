#Footer{
    /* background-color: #333333; */
    color: #000;
    width: 100%;
    font-size: .8rem;
    order: 2;
    font-weight: 300;
}

#Footer > div{
    
    border-top: 1px solid #ddd;
}

#Footer a{
    color: #000;
    text-decoration: none;
    border-right: 1px solid #ccc;
    padding: 0 10px;
}

#Footer a:last-child {
    border-right: none;
}