.Tree{
    position: relative;
}

.Tree>.Tree__item {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

.Tree>.Tree__item:last-child {
    border-bottom: 1px solid #ddd;
}

.Tree__item {
    /* border: 1px solid #ddd; */
    border-bottom: 0;
}

.Tree__item:first-child {
    border-radius: 3px 3px 0 0;
}

.Tree__item:last-child {
    border-radius: 0 0 3px 3px;
    /* border-bottom: 1px solid #ddd; */
}

.Tree__item--active>.Tree__body {
    display: block;
}

.Tree__item--active>.Tree__head {
    border-bottom: 1px solid #ddd;
}

.Tree__item--active>.Tree__body>.Tree__item:first-child>.Tree__head {
    border-top: 0;
}

/* Head */

.Tree__head {
    border-top: 1px solid #ddd;
    position: relative;
}

.Tree__head.Tree__folder {
    cursor: pointer;
}


/* .Tree__item:last-child .Tree__head{
    border-bottom: 0;
} */

/* .Tree__item:first-child > .Tree__head{
    border-bottom: 1px solid #ddd;
} */

.Tree__head:hover {
    background-color: #f5f5f5;
}

/* Name */

.Tree__name {
    padding: .8rem 1rem;
    padding-right: 6rem;
}

/* Options */

.Tree__options {
    position: absolute;
    right: 0;
    font-size: 28px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
}

.Tree__options svg {
    transition: .3s ease all;
}

.Tree__options svg:hover {
    background-color: #e8e8e8;
    cursor: pointer;
    color: #212529;
}

/* Body */

.Tree__body {
    display: none;
    margin-left: 2rem;
    border-left: 1px solid #ddd;
}

.Layer-loading {
    position: relative;
}


.Layer-loading {
    content: '';
    position: absolute;
    background-color: rgb(234 234 234 / 50%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: anim 5s infinite;
}
/* 
@-webkit-keyframes anim {
    from {
        background: #ee6055 !important;
    }

    to {
        background: #ff9b85 !important;
    }
  }

@keyframes anim {
    from {
        background: #ee6055 !important;
    }

    to {
        background: #ff9b85 !important;
    }
} */
