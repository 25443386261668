.Icon__Help{
    border-radius: 100%;
}

.Icon__Help svg{
    font-size: 1.4rem;
    transition: ease .3s all;
}

.Icon__Help svg:hover{
    color: #3ea7bc !important;
}