.listgroup-plantillas .list-group-item{
    cursor: pointer;
}

.listgroup-plantillas .list-group-item:hover{
     background-color: #f4f4f4;
}

.list-group-item.active {
    background-color: var(--bg-primary) !important;
    border-color: var(--bg-primary) !important;
}