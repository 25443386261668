

.title-ccetic {
  font-weight: bolder;
  color: #fff;
  margin-bottom: 1.5rem;
}

.footer-login {
  /* margin-top: 2rem;   */
  /* position: absolute; */
  /* margin-bottom: 1.5rem; */
}

.footer-login ul {
  list-style: none;
  display: flex;
}

.footer-login ul a {
  color: #000;
  margin: 0 0.5rem;
  font-size: 0.8rem;
  opacity: 0.7;
  text-decoration: none;
  transition: 0.2s all ease;
}

.footer-login ul a:hover {
  opacity: 1;
  /* text-decoration: underline; */
}

#logo {
  /* max-width: 180px; */
  display: block;
  /* margin: 0 auto; */
  /* padding-top: 1rem;
  padding-bottom: 1rem; */
  /* filter: brightness(0) invert(1); */
  width: 100%;
  /* position: absolute; */
  /* top: -14rem; */
}

#btn-registrate{
  color: #FFF;
  opacity: 0.7;
  transition: 0.2s all ease;
  text-decoration: none;
}

#btn-registrate:hover{
  opacity: 1;
}

#passwordHelp{
  color: #4C4184;
  color: var(--bg-primary);
  color: #333;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: .875em;
}

#login-start .nav-tabs{
  border-bottom: 0 !important;
  position: relative;
  display: flex;
  justify-content: center;
}

#login-start .nav-tabs::after{
  /* content: ''; */
  position: absolute;
  width: calc(100% - 2px);
  top: calc(100% - -1px);
  left: 1px;
  height: 10px;
  background-color: #fff;
  z-index: 15;
  border-radius: 0.25rem;
}

#login-start .tab-pane .card{
  border-top-left-radius: 0;
}

#login-start .nav-link{
  position: relative;
  z-index: 5;
  color: #8a8f94;
  border: 0;
  border-bottom: 2px solid #ddd;
}

@media (max-width: 768px) {

  #login-start .nav-link{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

}


#login-start .nav-link.active{
  color: #495057;
  /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
  background-color: transparent !important;
  border: 0;
  border-bottom: 2px solid var(--bg-primary);
}

#login-start .nav-link.active::after{
  /* content: ''; */
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #fff;
  left: 0;
  top: 100%;
  z-index: 5;
}

#login-start .card{
  border: 0;
  box-shadow: none !important;
  background-color: transparent;
}

#login-start .card-footer{
  background-color: transparent;
  border: 0;
  text-align: center;
  padding: 0;
}

.container-login .col-12:nth-child(1) .card-body{
  position: relative;
}

@media (min-width: 992px) {

  .container-login .col-12:nth-child(1) .card-body::after{
    content: '';
    position: absolute;
    height: 90%;
    bottom: 0;
    width: 1px;
    background-color: #ddd;
    right: 0;
  }

}

#ModalRegistro{
  
}

#list-provincia-poblacion{
  max-height: 125px;
  overflow: auto;
  font-size: .9rem;
  padding-top: .5rem;
}

#login-start .nav-link{
  padding-left: 2rem;
  padding-right: 2rem;
}